import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";

import CustomNavbar from "./components/navbar/Navbar";
import PricingRoute from "./HOC/PricingRoute";

const Home = lazy(() => import("./routes/Home"));
const Search = lazy(() => import("./routes/Search"));
const Developer = lazy(() => import("./routes/Developer"));
const Pricing = lazy(() => import("./routes/Pricing"));

// Rendered when page is loading
const renderLoader = () => (
  <>
    <main className="d-flex justify-content-center">
      <CircularProgress />
    </main>
  </>
);
export default function Router() {
  return (
    <>
      <CustomNavbar />
      <main>
        <Suspense fallback={renderLoader()}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/search" component={Search} />
            <Route exact path="/developer" component={Developer} />
            <PricingRoute exact path="/pricing" component={Pricing} />
            <Route render={() => <Redirect to={{ pathname: "/" }} />} />
          </Switch>
        </Suspense>
      </main>
    </>
  );
}
