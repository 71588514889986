import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import { useMediaQuery, useTheme } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import MenuIcon from "@material-ui/icons/Menu";

import logo from "url:../../assets/images/i10coderlogos.svg";
import {
  DEVELOPER_TAB,
  HOME_TAB,
  PERCEPTIVITI,
  PRICING_STATUS,
  PRICING_TAB,
  SEARCH_TAB,
} from "../../utils/constants";

export default function CustomNavbar() {
  const { pathname } = useLocation();

  const theme = useTheme();
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleLinkClick = () => {
    window.location = PERCEPTIVITI;
  };

  let tabName;
  if (pathname === "/") {
    tabName = HOME_TAB;
    document.title = "Home - i10Coder";
  } else if (pathname === "/developer") {
    tabName = DEVELOPER_TAB;
    document.title = "Developer - i10Coder";
  } else if (pathname === "/pricing") {
    tabName = PRICING_TAB;
    document.title = "Pricing - i10Coder";
  } else if (pathname === "/search") {
    tabName = SEARCH_TAB;
    document.title = "Search - i10Coder";
  } else {
    tabName = HOME_TAB;
  }

  return (
    <>
      <AppBar
        position="sticky"
        className="custom-navbar "
        style={{ backgroundColor: "white", boxShadow: "none" }}
      >
        <Toolbar
          className="d-flex justify-content-between"
          style={{ padding: "0" }}
        >
          <div className="d-flex ">
            <Link to="/home">
              <img
                style={{ verticalAlign: "top" }}
                src={logo}
                height="25"
                alt=""
              />
            </Link>

            {/* <h5 style={{ color: "black" }}>i10Coder</h5> */}
          </div>
          {isMobile ? (
            <>
              <IconButton edge="start" aria-label="menu" onClick={handleMenu}>
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchor}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                KeepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
              >
                <MenuItem
                  onClick={() => setAnchor(null)}
                  component={Link}
                  to="/"
                >
                  <Typography variant="h6"> Home</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => setAnchor(null)}
                  component={Link}
                  to="/developer"
                >
                  <Typography variant="h6"> Developer </Typography>
                </MenuItem>
                {PRICING_STATUS ? (
                  <MenuItem
                    onClick={() => setAnchor(null)}
                    component={Link}
                    to="/pricing"
                  >
                    <Typography variant="h6"> Pricing</Typography>
                  </MenuItem>
                ) : (
                  ""
                )}
                <MenuItem
                  onClick={() => {
                    setAnchor(null);
                    handleLinkClick();
                  }}
                >
                  <Typography variant="h6"> About </Typography>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <div className="navbar">
                <div>
                  <Link
                    className={
                      tabName === HOME_TAB
                        ? "nav-link text-uppercase active"
                        : "nav-link text-uppercase"
                    }
                    to="/home"
                  >
                    Home
                  </Link>
                </div>
                <div>
                  <Link
                    className={
                      tabName === DEVELOPER_TAB
                        ? "nav-link text-uppercase active"
                        : "nav-link text-uppercase"
                    }
                    to="/developer"
                  >
                    Developer
                  </Link>
                </div>
                {PRICING_STATUS ? (
                  <div>
                    <Link
                      className={
                        tabName === PRICING_TAB
                          ? "nav-link text-uppercase active"
                          : "nav-link text-uppercase"
                      }
                      to="/pricing"
                    >
                      Pricing
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                <div
                  tabIndex="0"
                  role="button"
                  className="nav-link text-uppercase"
                  variant="text"
                  onClick={handleLinkClick}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleLinkClick();
                    }
                  }}
                >
                  About
                </div>
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}
