import React from "react";
import { BrowserRouter } from "react-router-dom";

import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import "./sass/index.sass";
import Router from "./Router";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary";

import { GREEN, NAVBAR_BLUE } from "./utils/constants";
import { SearchProvider } from "./context/searchContext";

const theme = createTheme({
  palette: {
    primary: {
      main: NAVBAR_BLUE,
    },
    secondary: {
      main: GREEN,
    },
  },
});

function App() {
  return (
    <>
      <SearchProvider>
        <BrowserRouter>
          <ErrorBoundary>
            <MuiThemeProvider theme={theme}>
              <Router />
            </MuiThemeProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </SearchProvider>
    </>
  );
}

export default App;
