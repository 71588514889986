import React from "react";
import { Route, Redirect } from "react-router-dom";

import { PRICING_STATUS } from "../utils/constants";

export default function PricingRoute({ component: Component, ...rest }) {
  let isVisible = false;
  if (PRICING_STATUS) {
    isVisible = true;
  }
  return (
    <>
      <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={({ match, location }) =>
          isVisible ? (
            <Component match={match} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          )
        }
      />
    </>
  );
}
