import React from "react";
import { useHistory } from "react-router-dom";

export default function BackButton() {
  const history = useHistory();
  return (
    <button
      className="btn secondary-button rounded-pill px-4"
      type="button"
      onClick={() => {
        history.go(0);
      }}
    >
      Go Back
    </button>
  );
}
